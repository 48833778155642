// import ScrollTrigger from '@terwanerik/scrolltrigger';
// import { ajax } from 'jquery';
// import easing from 'jquery.easing';
// import Swiper from 'swiper';
// import swiper from 'swiper/bundle';
// import 'swiper/css';
// import 'swiper/css/bundle';
// import 'swiper/css/navigation';

// import Cookies from 'js-cookie';

// import lightcase from "lightcase";
// import 'lightcase/src/css/lightcase.css';


export class App {
  constructor(){
    this.html = document.documentElement;
    this.body = document.body;


    this.winW = window.innerWidth;
    this.winH = window.innerHeight;

    this.videoContainer = document.getElementById('videoContainer')
    this.videos = this.videoContainer.querySelectorAll('video');
    this.v1 = document.getElementById('v1')
    this.v2 = document.getElementById('v2')

    this.txtContainer = document.getElementById('txtContainer')
    this.btn = document.getElementById('btn')

    this.animContainer = document.getElementById('animation_container')
    // this.cur = this.v1;


    this.ua = this.isSp(this);
    this.sp = 1000;
    this.ease = "easeInOutExpo";

    this.vList = ["v1","v2","v3","v4","v5","v6"];

    
  }

  isSp(_t){
    let _ua_ar=[];
    let _ua;
    if(navigator.userAgent.match(/iPhone|iPad|iPod|Android.+Mobile/)){
      _ua_ar.push("mobile");
      _ua = "mobile";
      if(navigator.userAgent.match(/iPhone|iPod|Android.+Mobile/)){
        _ua_ar.push("m_sp");
      }
      if(navigator.userAgent.match(/iPad/)){
        // _ua_ar.push("m_pd");
      }
      // _t.makeSp();
      //console.log(_t);
      // console.log(navigator.userAgent);
    }else{
      _ua = "other";
      _ua_ar.push("other");
    }
    _ua_ar.forEach(_u => {
      _t.body.classList.add(_u);
    });
    //_t.body.classList.add(_ua_ar);
    return _ua;
  }

  
  
  init(){
    // console.log('init');
    
    this.vList = this.shuffle(this.vList);
    this.body.classList.add(this.vList.join('-'));

    this.v1.src = this.makeUrl(this.vList[0])
    this.v1_val = this.vList[0];
    this.v2.src = this.makeUrl(this.vList[1])
    this.v2_val = this.vList[1];

    this.v1.addEventListener('loadeddata', (e) => {
      // console.log("data="+e.currentTarget.duration)
      this.cur_dur = e.currentTarget.duration;
    })

    window.addEventListener('load', (e) => {
      $('.spinner').fadeOut('slow',function(){
        $(this).remove();
      });
      this.body.classList.add('loaded');

      this.v1.play();


			this.makeSp();

      this.resize();

      this.events();

    });
    
  }

  shuffle(_arr){

    for(const [index, video] of _arr.entries()) {
      // console.log(index, video)
      const tempIndex = Math.floor(Math.random() * _arr.length)
      const tempVideo = _arr[index]
      _arr[index] = _arr[tempIndex]
      _arr[tempIndex] = tempVideo
    }
    
    return _arr;
    // console.log(_arr);
  }
  queryMatch(jud){
    // console.log(jud);
    if(jud === 'sp'){

    }else{
      
      // console.log(this.menuOpen);
    }
  }
  makeUrl(_val){
    let _url = "images/"+_val+".mp4"
    if(this.ua == "mobile"){
      _url = "images/"+_val+"_sp.mp4"
    }
    return _url
  }
  change(){
    // console.log('change');
    this.videoContainer.querySelectorAll('video').forEach(
    _v => {
      if(_v.classList.contains('cur')){
        _v.classList.remove('cur')
        if(_v.id === "v1"){
          const _v1_num = parseInt(this.vList.indexOf(this.v1_val)) + 2;
          // console.log(_v1_num)

          if(_v1_num <= this.vList.length-1){
            _v.src = this.makeUrl(this.vList[_v1_num])
            this.v1_val = this.vList[_v1_num]
            // console.log(this.v1_val)
          }else{
            _v.src = this.makeUrl(this.vList[0])
          this.v1_val = this.vList[0]
          }
        }else{

          const _v2_num = parseInt(this.vList.indexOf(this.v2_val)) + 2;

          if(_v2_num <= this.vList.length-1){
            _v.src = this.makeUrl(this.vList[_v2_num])
            this.v2_val = this.vList[_v2_num]
          }else{
            _v.src = this.makeUrl(this.vList[1])
          this.v2_val = this.vList[1]
          }
        }
        
        

      }else{
        _v.classList.add('cur')
        _v.play();
        // _v.addEventListener('ended', (e) => {
        //   console.log(e.currentTarget)
        //   this.change();
        // })
      }
    }
   )
  }

  events(){

    setTimeout((e) => {
      this.animContainer.classList.add('on')
      init();
    },2000)

    this.v1.addEventListener('ended',(e) => {
      // console.log(e.currentTarget)
      this.change();
    })
    this.v2.addEventListener('ended',(e) => {
      // console.log(e.currentTarget)
      this.change();
    })

    this.btn.addEventListener('click', (e) => {
      if(this.txtContainer.classList.contains('on')){
        this.txtContainer.classList.remove('on')
      }else{
        this.txtContainer.classList.add('on')
      }
      const _b = e.currentTarget;
      if(_b.classList.contains('on')){
        _b.classList.remove('on')
        _b.querySelector('.bar:nth-child(1)').style.animationName='bar1'
        _b.querySelector('.bar:nth-child(3)').style.animationName='bar3'
      }else{
        _b.classList.add('on')
      }
    } )
    window.addEventListener('blur', (e) => {
      // console.log('blur')
    })
    window.addEventListener('focus', (e) => {
      // console.log('focus')
      const _cur = document.querySelector('video.cur')

      if(_cur.paused || _cur.ended){
        _cur.play();
      }
    })
    window.addEventListener('scroll', (e) => { this.scroll(); });
    window.addEventListener('resize',(e)=>{this.resize();});

   

    this.bet = 0;
    const options = {
      root:null,
      rootMargin:`${this.bet}px`,
      threshold: 0.1
    };

    const observer = new IntersectionObserver(entries => {
      entries.forEach(
        entry => {
          // console.log(entry.isIntersecting);
          // if(entry.isIntersecting){
          //   this.btnWrap.classList.remove('on');
          // }else{
          //   this.btnWrap.classList.add('on');
          // }
        //  if(entry.target.classList.contains('headerContainer')){
        //   // console.log(entry.intersectionRatio);
        //   if(entry.intersectionRatio < 0.1 ){
        //     // console.log(entry.intersectionRatio);
        //     // this.btn_pagetop.classList.add('on');
        //   }else{
        //     // console.log(entry.intersectionRatio);
        //     // this.btn_pagetop.classList.remove('on');
        //   }
        //  }

        }
      )
    }, options);




    const mediaQueryList = window.matchMedia('(min-width:897px)');
    const listener = (e) => {
      if(e.matches){
        this.queryMatch('pc');
        
      }else{
        this.queryMatch('sp');
      }
    }
    mediaQueryList.addEventListener('change', listener);

    listener(mediaQueryList);
    

  }
  

  makeSp(){
  }


  scroll(){
  }
  resize(){
    this.get_size();
   
  }
  get_size(){
    this.winW = window.innerWidth;
    this.winH = window.innerHeight;
  }
  anim(_t,_tt=this.sp) {
    const rect = _t.getBoundingClientRect();
    const scT = window.pageYOffset || document.documentElement.scrollTop;
    let targetT  = rect.top;

    targetT = rect.top + scT - this.headerH;

    if(this.body.classList.contains('foo-xx')){
       // - this.headerH;
       targetT = rect.top + scT - this.headerH - this.catListContainer.offsetHeight;
      //  console.log(document.getElementById('catListContainer').offsetHeight);
    }
    // console.log(_tt);
    $('html,body').animate({
      scrollTop: targetT
    }, _tt, this.ease);
  }


  
}
