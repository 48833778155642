import 'reset-css/reset.css';
import '../scss/site.scss';

import {App} from './Lib';
// import {UtilClass} from './Util';

window.app = new App();
// window.util_class = new UtilClass();
app.init();
// util_class.init();
